import React  from 'react'; 
import ServiceRender  from './ServiceRender';

const Service = () =>{

return (
  <> 
  <ServiceRender />
 
    
</>
);
}

export default Service;
