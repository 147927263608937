const data = [
    {
        name : "Asmi Mittal",
        description : "I have lost approx 3kg with great inch loss in a short period of 2 months. It was very much difficult...."
        ,link : "https://goo.gl/maps/N3CRoLi9RNPKDZFW8"
    },    
    {
        name : "Iti Sharma",
        description : "It had been a wonderful experience with Balanced Bites. I gained so much knowledge related to food The best part..."
        ,link : "https://goo.gl/maps/QdnZyj43FHeKWxjH8"
    },
    {
        name : "Nehal Rupla",
        description : "Diet chart is too good which has been provided to me by dietician muskan bansal love the diet chart..."
        ,link :"https://goo.gl/maps/uEkGL1m9Aj81jZZz9"
    }

];

export default data;